<template>
  <div class="invoice-body" id="invoice-body">
    <div v-show="show" id="invoice">
      <div :style="{'padding': '10px','background-color': '#ffffff','direction': 'rtl', 'text-align': 'right' }">
<!--        <div v-if="rel_logo" style="width: 100%; text-align: center;padding-bottom: 10px;padding-top: 15px;"><img :src="rel_logo" alt="" style="width: 80px;"></div>-->
        <h4 class="text-center font-weight-800">فاتورة طلب Request invoice</h4>

        <h4 class="text-center font-weight-800" style="margin-top: 15px">
          رقم الفاتورة: {{ data.invoice_code }}
        </h4>
        <h4 class="text-center font-weight-800" style="margin-top: 0px">
          تاريخ الفاتورة: {{ data.create_date_time }}
        </h4>

        <h3 class="text-center" style="margin-bottom: 5px;margin-top: 5px;" v-if="data.company && data.company.business_name">{{ data.company.business_name }}</h3>
        <p class="text-center" style="margin-bottom: 5px;margin-top: 5px;">
          <span>{{ data.company ? (data.company.city_name ? ' - ' + data.company.city_name : '') : '' }}</span>
          <span>{{ data.company ? (data.company.address_1 ? ' - ' + data.company.address_1 : '') : '' }}</span>
          <span>{{ data.company ? (data.company.postal_code ? ' - ' + data.company.postal_code : '') : '' }}</span>
        </p>

       

<!--        <p><span style="border: 1px solid #000;width: 70%;height: 0px;display: block;margin: 0 auto;"></span></p>-->

<!--        <div style="border-right: 1px solid #000000;margin-top: 15px;padding: 5px 10px;">-->
<!--          <p style="margin-bottom: 0;margin-top: 0;"><span><b> تاريخ الفاتورة: </b> <span> {{ data.create_date_time }} </span></span></p>-->

<!--        </div>-->

        <p><span style="border: 1px solid #000;width: 70%;height: 0px;display: block;margin: 0 auto;"></span></p>

        <div style="border-right: 1px solid #000000;margin-top: 15px;padding: 5px 10px;">
          <p style="margin-bottom: 0;margin-top: 10px;">
            <span v-if="data.employee_user_name">
              <b> أمين الصندوق: </b>
              <span> {{ data.employee_user_name }} </span>
            </span>
          </p>
        </div>
        <p><span style="border: 1px solid #000;width: 70%;height: 0px;display: block;margin: 0 auto;"></span></p>



        <div style="margin-top: 15px;">
          <table class="table-p" style="margin-top: 15px">
            <thead>
            <tr>
              <th>المنتج</th>
              <th class="text-center">الكمية</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(row, index) in items_list" :key="index">
              <td style="overflow: hidden;">
                <span>
                {{ row.item ? row.item.name : '' }}
              </span>
              </td>
              <td class="text-center">{{ row.qty }}</td>
            </tr>
            </tbody>
          </table>
        </div>

        <div style="margin-top: 15px;">
          <div style="min-width: 264px;padding-left: 5px;">
            <div class="d-flex justify-content-between special-border mt-1">
              <h5 class="mb-0">
              <span v-if="is_apply_taxes_invoice">
                الاجمالي شامل الضريبة \ Total
              </span>
                <span v-else>
                الاجمالي \ Total
              </span>
              </h5>
              <div><span><b>{{ data.invoice_total ? data.invoice_total : '0' }}</b></span> <span> {{ data.currency_code }} </span></div>
            </div>
          </div>
        </div>

      </div>

    </div>
  </div>

</template>
<script>
import ApiService from "@/core/services/api.service";
import VueQRCodeComponent from 'vue-qrcode-component';
import {jsPDF} from "jspdf";
import html2canvas from "html2canvas";


export default {
  name: "point-sale-invoice",
  props: {printEleShow: {type: Function}},
  components: {
    'qr-code': VueQRCodeComponent
  },
  data() {
    return {
      fetched: false,
      lang: this.$i18n.locale,
      mainRoute: 'pos/point-sale',
      data: {},
      items_list: [],
      size: 175,
      calc_total: 0,
      printWindow: '',
      show: false,

      templateMainRoute: 'settings/print_setting/type',
      template_for: 'PosSales',
      template: null,
      content_temp: [],
      is_break_before_footer: false,
      is_payment_details: false,
      is_empty_row: false,
      is_show_contact: false,
      is_show_customer_details: false,
      is_show_terms_and_conditions: false,
      products_template_design: 1,
      print_size: '80',
      customer_type: null,
      is_company_have_tax: false,
      is_apply_taxes_invoice: false,

    }
  },
  computed: {
    rel_logo: function () {
      if (this.data && this.data.company && this.data.company.logo_url) {
        return this.data.company.logo_url;
      } else {
        return '';
      }
    }
  },

  methods: {
    async getData() {
      await ApiService.get(`${this.mainRoute}/print_last_invoice`).then((response) => {
        this.data = response.data.data;
        this.items_list = response.data.data.items_list;
        this.show = true;


      });
    },
    printData() {
      this.printContent();
    },
    printContent() {

      if (document.getElementById('invoice-body')) {
        const content = document.getElementById('invoice-body').innerHTML;

        const printWindow = window.open('', '_self');

        printWindow.document.write('<html><head><title>Print</title>');

        printWindow.document.write('<style>');
        printWindow.document.write(`
              @media print {
                @page {
                  size: auto;
                  margin: 2mm;
                }
              }
              .store-logo{
                position: absolute;
                top: 0;
                right: 0;
                height: 99px;
                margin: 13px;
              }
              #invoice {
                font-family: "NotoSansArabic";
                width: ${this.print_size}mm;
                position: relative;
              }
              h5{
                margin:0;
              }
              html, body {
                    margin: 0.39mm 0.27mm;
                    background: #EEF0F8;
              }

              table, thead, tr, th, tbody, td {

                font-size: 14px;
                background-color: transparent !important;
              }

              table{
                border: 1px solid #000000;
                border-radius: 6px;
                border-color: #000000 !important;
                width: 100%;
              }

              .text-center {
                text-align: center !important;
              }

              th, p, h5 {

                font-size: 14px !important;

              }

              .special-border {
                padding: 5px 0px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 5px;
              }
              .table td {
                border-left: 1px solid #000000 !important;
                border-top: 0;
              }
              .table thead th {
                border-left: 1px solid #000000;
                border-bottom: 1px solid #000000;
              }
              .table thead tr {
                border: 0 !important;
              }
              .table thead th:last-child {
                border-left: 0 !important;
              }
              .table td:last-child {
                border-left: 0 !important;
              }
              .table th, .table td {
                padding: 5px !important;
              }
.table-p td {
  border-left: 1px solid #000000 !important;
  border-top: 0 !important;
}

.table-p thead th {
  border-left: 1px solid #000000 !important;
  border-bottom: 1px solid #000000 !important;
}

.table-p thead tr {
  border: 0 !important;
}

.table-p thead th:last-child {
  border-left: 0 !important;
}

.table-p td:last-child {
  border-left: 0 !important;
}

.table-p th, .table-p td {
  padding: 5px !important;
}
          `);
        printWindow.document.write('</style>');

        printWindow.document.write('</head><body>');
        printWindow.document.write(content);
        printWindow.document.write('</body></html>');


        setTimeout(() => {
          printWindow.print();
          printWindow.close();
          // this.completedForPrint();
        }, 300);
      }
    },
    // printData() {
    //
    //   let element = document.getElementById('invoice');
    //   let width = element.clientWidth - 110;
    //   let height = element.clientHeight;
    //
    //   let pdf = new jsPDF({
    //     orientation: 'p',
    //     unit: 'px',
    //     format: [width, height]
    //   });
    //   html2canvas(element).then(canvas => {
    //     let image = canvas.toDataURL('image/png', 1.0);
    //     pdf.addImage(image, 'PNG', 15, 15);
    //     // pdf.save('invoice'+'.pdf');
    //     if (this.type == 'print') {
    //       pdf.autoPrint();
    //     }
    //     let _url = pdf.output("bloburl");
    //     location.href = _url;
    //   });
    //
    // },

    completedForPrint(){
      let imgs = document.images,
          len = imgs.length,
          counter = 0;

      [].forEach.call( imgs, function( img ) {
        if(img.complete)
          incrementCounter();
        else
          img.addEventListener( 'load', incrementCounter, false );
      } );
      function incrementCounter() {
        counter++;
        if ( counter === len ) {
          window.print();
          window.close();
        }
      }

    },
    mobileFullData(mobile) {
      if (mobile) {
        if (mobile.length > 5) {
          return mobile;
        }
      }
      return null;
    },
  },
  mounted() {
    let promise = this.getData();
    Promise.all([promise]).then(() => {
      // this.$emit('print-ele-show');
      this.printData();
      // // Execution this after the content be ready
      // Promise.all([promise2]).then(() => {
      //   that.printWindow.print();
      // });

    });
  }
}
</script>

<style scoped>
@media print {
  @page {
    size: auto;
    margin: 0;
  }

  .store-logo {
    position: absolute;
    top: 0;
    right: 0;
    height: 99px;
    margin: 13px;
  }

  .print-action-holder, #kt_aside, #kt_header, #kt_subheader, #kt_footer, #kt_header_mobile {
    display: none !important;
  }

  .print-action-holder {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .print-action-holder__btn-print {
    background: #0c6f58;
    color: white;
    padding: 10px 20px;
    margin: 10px auto;
    border-radius: 20px;
  }

  #invoice {
    font-family: "NotoSansArabic";
    width: 525px;
    position: relative;
    margin: 0 auto;
    border: 1px solid;
  }


  html, body {
    width: 525px;
    margin: 0 auto;
  }

  table, thead, tr, th, tbody, td {
    font-size: 20px;
    background-color: transparent !important;
  }

  table {
    border: 1px solid #000000;
    border-radius: 6px;
    border-color: #000000 !important;
  }

  .text-center {
    text-align: center !important;
  }

  th, p, h5, span {
    font-size: 20px !important;
  }

  .special-border {
    border: 1px solid #000000;
    border-radius: 6px;
    padding: 5px 10px;
  }

  .table td {
    border-left: 1px solid #000000 !important;
    border-top: 0;
  }

  .table thead th {
    border-left: 1px solid #000000;
    border-bottom: 1px solid #000000;
  }

  .table thead tr {
    border: 0 !important;
  }

  .table thead th:last-child {
    border-left: 0 !important;
  }

  .table th, .table td {
    padding: 5px !important;
  }

  .table-p td {
    border-left: 1px solid #000000 !important;
    border-top: 0 !important;
  }

  .table-p thead th {
    border-left: 1px solid #000000 !important;
    border-bottom: 1px solid #000000 !important;
  }

  .table-p thead tr {
    border: 0 !important;
  }

  .table-p thead th:last-child {
    border-left: 0 !important;
  }

  .table-p td:last-child {
    border-left: 0 !important;
  }

  .table-p th, .table-p td {
    padding: 5px !important;
  }
}

.table-p td {
  border-left: 1px solid #000000 !important;
  border-top: 0 !important;
}

.table-p thead th {
  border-left: 1px solid #000000 !important;
  border-bottom: 1px solid #000000 !important;
}

.table-p thead tr {
  border: 0 !important;
}

.table-p thead th:last-child {
  border-left: 0 !important;
}

.table-p td:last-child {
  border-left: 0 !important;
}

.table-p th, .table-p td {
  padding: 5px !important;
}
</style>